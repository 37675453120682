var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.saveEmailConfig.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "grid grid-cols-1 gap-4 mb-4 md:grid-cols-2 md:mb-6" },
        [
          _c(
            "sw-input-group",
            {
              attrs: {
                label: _vm.$t("wizard.mail.driver"),
                error: _vm.driverError,
                required: "",
              },
            },
            [
              _c("sw-select", {
                attrs: {
                  invalid: _vm.$v.mailConfigData.mail_driver.$error,
                  options: _vm.mailDrivers,
                  "allow-empty": false,
                  searchable: true,
                  "show-labels": false,
                },
                on: { input: _vm.onChangeDriver },
                model: {
                  value: _vm.mailConfigData.mail_driver,
                  callback: function ($$v) {
                    _vm.$set(_vm.mailConfigData, "mail_driver", $$v)
                  },
                  expression: "mailConfigData.mail_driver",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "grid grid-cols-1 gap-4 mb-6 md:grid-cols-2" },
        [
          _c(
            "sw-input-group",
            {
              attrs: {
                label: _vm.$t("wizard.mail.from_name"),
                error: _vm.fromNameError,
                required: "",
              },
            },
            [
              _c("sw-input", {
                attrs: {
                  invalid: _vm.$v.mailConfigData.from_name.$error,
                  type: "text",
                  name: "name",
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.mailConfigData.from_name.$touch()
                  },
                },
                model: {
                  value: _vm.mailConfigData.from_name,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.mailConfigData,
                      "from_name",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "mailConfigData.from_name",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              attrs: {
                label: _vm.$t("wizard.mail.from_mail"),
                error: _vm.fromMailError,
                required: "",
              },
            },
            [
              _c("sw-input", {
                attrs: {
                  invalid: _vm.$v.mailConfigData.from_mail.$error,
                  type: "text",
                  name: "from_mail",
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.mailConfigData.from_mail.$touch()
                  },
                },
                model: {
                  value: _vm.mailConfigData.from_mail,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.mailConfigData,
                      "from_mail",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "mailConfigData.from_mail",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "sw-button",
        {
          staticClass: "mt-4",
          attrs: {
            loading: _vm.loading,
            disabled: _vm.loading,
            variant: "primary",
            type: "submit",
          },
        },
        [
          !_vm.loading ? _c("save-icon", { staticClass: "mr-2" }) : _vm._e(),
          _vm._v("\n    " + _vm._s(_vm.$t("general.save")) + "\n  "),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }