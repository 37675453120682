var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "fixed top-0 left-0 hidden h-full pt-16 pb-4 ml-56 bg-white xl:ml-64 w-88 xl:block",
    },
    [
      _c(
        "div",
        {
          staticClass:
            "flex items-center justify-between px-4 pt-8 pb-2 border border-gray-200 border-solid height-full",
        },
        [
          _c(
            "sw-input",
            {
              staticClass: "mb-6",
              attrs: {
                placeholder: _vm.$t("general.search"),
                type: "text",
                variant: "gray",
              },
              on: {
                input: function ($event) {
                  return _vm.onSearch()
                },
              },
              model: {
                value: _vm.searchData.searchText,
                callback: function ($$v) {
                  _vm.$set(_vm.searchData, "searchText", $$v)
                },
                expression: "searchData.searchText",
              },
            },
            [
              _c("search-icon", {
                staticClass: "h-5",
                attrs: { slot: "rightIcon" },
                slot: "rightIcon",
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "flex mb-6 ml-3",
              attrs: { role: "group", "aria-label": "First group" },
            },
            [
              _c(
                "sw-dropdown",
                {
                  attrs: {
                    "close-on-select": false,
                    align: "left",
                    position: "bottom-start",
                  },
                },
                [
                  _c(
                    "sw-button",
                    {
                      attrs: {
                        slot: "activator",
                        size: "md",
                        variant: "gray-light",
                      },
                      slot: "activator",
                    },
                    [_c("filter-icon", { staticClass: "h-5" })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "px-2 py-1 pb-2 mb-2 text-sm border-b border-gray-200 border-solid",
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("general.sort_by")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-dropdown-item",
                    { staticClass: "flex cursor-pointer" },
                    [
                      _c(
                        "sw-input-group",
                        { staticClass: "-mt-3 font-normal" },
                        [
                          _c("sw-radio", {
                            attrs: {
                              id: "filter_create_date",
                              label: _vm.$t("customers.create_date"),
                              size: "sm",
                              name: "filter",
                              value: "invoices.created_at",
                            },
                            on: { change: _vm.onSearch },
                            model: {
                              value: _vm.searchData.orderByField,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchData, "orderByField", $$v)
                              },
                              expression: "searchData.orderByField",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-dropdown-item",
                    { staticClass: "flex cursor-pointer" },
                    [
                      _c(
                        "sw-input-group",
                        { staticClass: "-mt-3 font-normal" },
                        [
                          _c("sw-radio", {
                            attrs: {
                              id: "filter_display_name",
                              label: _vm.$t("customers.display_name"),
                              size: "sm",
                              name: "filter",
                              value: "users.name",
                            },
                            on: { change: _vm.onSearch },
                            model: {
                              value: _vm.searchData.orderByField,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchData, "orderByField", $$v)
                              },
                              expression: "searchData.orderByField",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-button",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip.top-center",
                      value: { content: _vm.getOrderName },
                      expression: "{ content: getOrderName }",
                      modifiers: { "top-center": true },
                    },
                  ],
                  staticClass: "ml-1",
                  attrs: { size: "md", variant: "gray-light" },
                  on: { click: _vm.sortData },
                },
                [
                  _vm.getOrderBy
                    ? _c("sort-ascending-icon", { staticClass: "h-5" })
                    : _c("sort-descending-icon", { staticClass: "h-5" }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isSearching
        ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
        : _c(
            "div",
            {
              staticClass:
                "h-full pb-32 overflow-y-scroll border-l border-gray-200 border-solid sidebar sw-scroll",
            },
            [
              _vm._l(_vm.customers, function (customer, index) {
                return _c(
                  "router-link",
                  {
                    key: index,
                    class: [
                      "flex justify-between p-4 items-center cursor-pointer hover:bg-gray-100 border-l-4 border-transparent",
                      {
                        "bg-gray-100 border-l-4 border-primary-500 border-solid":
                          _vm.hasActiveUrl(customer.id),
                      },
                    ],
                    staticStyle: {
                      "border-top": "1px solid rgba(185, 193, 209, 0.41)",
                    },
                    attrs: {
                      to: "/admin/customers/" + customer.id + "/view",
                      id: "customer-" + customer.id,
                    },
                  },
                  [
                    _c("div", [
                      _c(
                        "div",
                        {
                          staticClass:
                            "pr-2 text-sm not-italic font-normal leading-5 text-black capitalize truncate",
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(customer.name) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      customer.contact_name
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "mt-1 text-xs not-italic font-medium leading-5 text-gray-600",
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(customer.contact_name) +
                                  "\n        "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "flex-1 whitespace-nowrap right" },
                      [
                        _c("div", {
                          staticClass:
                            "text-xl not-italic font-semibold leading-8 text-right text-gray-900",
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$utils.formatMoney(
                                customer.due_amount,
                                customer.currency
                              )
                            ),
                          },
                        }),
                      ]
                    ),
                  ]
                )
              }),
              _vm._v(" "),
              !_vm.customers.length
                ? _c(
                    "p",
                    {
                      staticClass:
                        "flex justify-center px-4 mt-5 text-sm text-gray-600",
                    },
                    [
                      _vm._v(
                        "\n      " +
                          _vm._s(_vm.$t("customers.no_matching_customers")) +
                          "\n    "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            2
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }