var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "sw-wizard-step",
    {
      attrs: {
        title: _vm.$t("wizard.account_info"),
        description: _vm.$t("wizard.account_info_desc"),
      },
    },
    [
      _c(
        "form",
        {
          attrs: { action: "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.next()
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "grid grid-cols-1 mb-4 md:grid-cols-2 md:mb-6" },
            [
              _c(
                "sw-input-group",
                {
                  attrs: {
                    label: _vm.$tc("settings.account_settings.profile_picture"),
                  },
                },
                [
                  _c("sw-avatar", {
                    attrs: {
                      "preview-avatar": _vm.previewAvatar,
                      label: _vm.$tc("general.choose_file"),
                    },
                    on: {
                      changed: _vm.onChange,
                      uploadHandler: _vm.onUploadHandler,
                      handleUploadError: _vm.onHandleUploadError,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "icon",
                        fn: function () {
                          return [
                            _c("cloud-upload-icon", {
                              staticClass:
                                "h-5 mb-2 text-xl leading-6 text-gray-400",
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "grid grid-cols-1 gap-4 mb-4 md:grid-cols-2 md:mb-6",
            },
            [
              _c(
                "sw-input-group",
                {
                  attrs: {
                    label: _vm.$t("wizard.name"),
                    error: _vm.nameError,
                    required: "",
                  },
                },
                [
                  _c("sw-input", {
                    attrs: {
                      invalid: _vm.$v.profileData.name.$error,
                      type: "text",
                      name: "name",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.profileData.name.$touch()
                      },
                    },
                    model: {
                      value: _vm.profileData.name,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.profileData,
                          "name",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "profileData.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  attrs: {
                    label: _vm.$t("wizard.email"),
                    error: _vm.emailError,
                    required: "",
                  },
                },
                [
                  _c("sw-input", {
                    attrs: {
                      invalid: _vm.$v.profileData.email.$error,
                      type: "text",
                      name: "email",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.profileData.email.$touch()
                      },
                    },
                    model: {
                      value: _vm.profileData.email,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.profileData,
                          "email",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "profileData.email",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "grid grid-cols-1 gap-4 mb-6 md:grid-cols-2" },
            [
              _c(
                "sw-input-group",
                {
                  attrs: {
                    label: _vm.$t("wizard.password"),
                    error: _vm.passwordError,
                    required: "",
                  },
                },
                [
                  _c("sw-input", {
                    attrs: {
                      invalid: _vm.$v.profileData.password.$error,
                      type: "password",
                      name: "password",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.profileData.password.$touch()
                      },
                    },
                    model: {
                      value: _vm.profileData.password,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.profileData,
                          "password",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "profileData.password",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  attrs: {
                    label: _vm.$t("wizard.confirm_password"),
                    error: _vm.confirmPasswordError,
                    required: "",
                  },
                },
                [
                  _c("sw-input", {
                    attrs: {
                      invalid: _vm.$v.profileData.confirm_password.$error,
                      type: "password",
                      name: "confirm_password",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.profileData.confirm_password.$touch()
                      },
                    },
                    model: {
                      value: _vm.profileData.confirm_password,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.profileData,
                          "confirm_password",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "profileData.confirm_password",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-button",
            {
              staticClass: "mt-4",
              attrs: {
                loading: _vm.isLoading,
                disabled: _vm.isLoading,
                variant: "primary",
                type: "submit",
              },
            },
            [
              !_vm.isLoading
                ? _c("save-icon", { staticClass: "mr-2" })
                : _vm._e(),
              _vm._v(
                "\n      " + _vm._s(_vm.$t("wizard.save_cont")) + "\n    "
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }