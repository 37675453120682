var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "sw-wizard-step",
    {
      attrs: {
        title: _vm.$t("wizard.mail.mail_config"),
        description: _vm.$t("wizard.mail.mail_config_desc"),
      },
    },
    [
      _vm.isFetching
        ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { action: "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.next()
            },
          },
        },
        [
          _c(_vm.mail_driver, {
            tag: "component",
            attrs: {
              "config-data": _vm.mailConfigData,
              loading: _vm.isLoading,
              "mail-drivers": _vm.mail_drivers,
            },
            on: {
              "on-change-driver": function (val) {
                return (_vm.mail_driver = _vm.mailConfigData.mail_driver = val)
              },
              "submit-data": _vm.next,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }