var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "sw-wizard-step",
    {
      attrs: {
        title: _vm.$t("wizard.verify_domain.title"),
        description: _vm.$t("wizard.verify_domain.desc"),
      },
    },
    [
      _c(
        "div",
        { staticClass: "w-full md:w-2/3" },
        [
          _c(
            "sw-input-group",
            {
              attrs: {
                label: _vm.$t("wizard.verify_domain.app_domain"),
                error: _vm.domainError,
                required: "",
              },
            },
            [
              _c("sw-input", {
                attrs: {
                  invalid: _vm.$v.formData.app_domain.$error,
                  type: "text",
                  name: "name",
                  placeholder: "crater.com",
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.formData.app_domain.$touch()
                  },
                },
                model: {
                  value: _vm.formData.app_domain,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.formData,
                      "app_domain",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "formData.app_domain",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("p", { staticClass: "mt-4 mb-0 text-sm text-gray-600" }, [
        _vm._v("Notes:"),
      ]),
      _vm._v(" "),
      _c("ul", { staticClass: "w-full text-gray-600 list-disc list-inside" }, [
        _c("li", { staticClass: "text-sm leading-8" }, [
          _vm._v("\n      App domain should not contain\n      "),
          _c("b", { staticClass: "inline-block px-1 bg-gray-100 rounded-sm" }, [
            _vm._v("https://"),
          ]),
          _vm._v(" or\n      "),
          _c("b", { staticClass: "inline-block px-1 bg-gray-100 rounded-sm" }, [
            _vm._v("http"),
          ]),
          _vm._v(" in front of\n      the domain.\n    "),
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "text-sm leading-8" }, [
          _vm._v(
            "\n      If you're accessing the website on a different port, please mention the\n      port. For example:\n      "
          ),
          _c("b", { staticClass: "inline-block px-1 bg-gray-100" }, [
            _vm._v("localhost:8080"),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "sw-button",
        {
          staticClass: "mt-8",
          attrs: {
            loading: _vm.isLoading,
            disabled: _vm.isLoading,
            variant: "primary",
          },
          on: { click: _vm.verifyDomain },
        },
        [
          _vm._v(
            "\n    " +
              _vm._s(_vm.$t("wizard.verify_domain.verify_now")) +
              "\n  "
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }