var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sw-date-picker", {
    ref: "BaseDatepicker",
    attrs: {
      config: _vm.config,
      placeholder: _vm.placeholder,
      disabled: _vm.disabled,
      invalid: _vm.invalid,
      name: _vm.name,
      tabindex: _vm.tabindex,
    },
    on: { input: _vm.onDateChange },
    model: {
      value: _vm.time,
      callback: function ($$v) {
        _vm.time = $$v
      },
      expression: "time",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }