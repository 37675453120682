var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-page",
    { staticClass: "relative estimate-create-page" },
    [
      !_vm.isLoadingEstimate && !_vm.isLoadingData
        ? _c(
            "form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submitForm.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "sw-page-header",
                { attrs: { title: _vm.pageTitle } },
                [
                  _c(
                    "sw-breadcrumb",
                    { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
                    [
                      _c("sw-breadcrumb-item", {
                        attrs: {
                          title: _vm.$t("general.home"),
                          to: "/admin/dashboard",
                        },
                      }),
                      _vm._v(" "),
                      _c("sw-breadcrumb-item", {
                        attrs: {
                          title: _vm.$tc("estimates.estimate", 2),
                          to: "/admin/estimates",
                        },
                      }),
                      _vm._v(" "),
                      _vm.$route.name === "estimates.edit"
                        ? _c("sw-breadcrumb-item", {
                            attrs: {
                              title: _vm.$t("estimates.edit_estimate"),
                              to: "#",
                              active: "",
                            },
                          })
                        : _c("sw-breadcrumb-item", {
                            attrs: {
                              title: _vm.$t("estimates.new_estimate"),
                              to: "#",
                              active: "",
                            },
                          }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "template",
                    { slot: "actions" },
                    [
                      _vm.$route.name === "estimates.edit"
                        ? _c(
                            "sw-button",
                            {
                              staticClass: "mr-3",
                              attrs: {
                                href:
                                  "/estimates/pdf/" +
                                  _vm.newEstimate.unique_hash,
                                "tag-name": "a",
                                target: "_blank",
                                variant: "primary-outline",
                                type: "button",
                              },
                            },
                            [
                              _c("span", { staticClass: "flex" }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("general.view_pdf")) +
                                    "\n          "
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "sw-button",
                        {
                          attrs: {
                            loading: _vm.isLoading,
                            disabled: _vm.isLoading,
                            variant: "primary",
                            type: "submit",
                            size: "lg",
                          },
                        },
                        [
                          !_vm.isLoading
                            ? _c("save-icon", { staticClass: "mr-2 -ml-1" })
                            : _vm._e(),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("estimates.save_estimate")) +
                              "\n        "
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "grid-cols-12 gap-8 mt-6 mb-8 lg:grid" },
                [
                  _c("customer-select", {
                    staticClass: "col-span-5 pr-0",
                    attrs: {
                      valid: _vm.$v.selectedCustomer,
                      "customer-id": _vm.customerId,
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "grid grid-cols-1 col-span-7 gap-4 mt-8 lg:gap-6 lg:mt-0 lg:grid-cols-2",
                    },
                    [
                      _c(
                        "sw-input-group",
                        {
                          attrs: {
                            label: _vm.$t("reports.estimates.estimate_date"),
                            erorr: _vm.estimateDateError,
                            required: "",
                          },
                        },
                        [
                          _c("base-date-picker", {
                            staticClass: "mt-2",
                            attrs: {
                              "calendar-button": true,
                              "calendar-button-icon": "calendar",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.newEstimate.estimate_date.$touch()
                              },
                            },
                            model: {
                              value: _vm.newEstimate.estimate_date,
                              callback: function ($$v) {
                                _vm.$set(_vm.newEstimate, "estimate_date", $$v)
                              },
                              expression: "newEstimate.estimate_date",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          attrs: {
                            label: _vm.$t("estimates.due_date"),
                            error: _vm.expiryDateError,
                            required: "",
                          },
                        },
                        [
                          _c("base-date-picker", {
                            staticClass: "mt-2",
                            attrs: {
                              invalid: _vm.$v.newEstimate.expiry_date.$error,
                              "calendar-button": true,
                              "calendar-button-icon": "calendar",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.newEstimate.expiry_date.$touch()
                              },
                            },
                            model: {
                              value: _vm.newEstimate.expiry_date,
                              callback: function ($$v) {
                                _vm.$set(_vm.newEstimate, "expiry_date", $$v)
                              },
                              expression: "newEstimate.expiry_date",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "lg:mt-0",
                          attrs: {
                            label: _vm.$t("estimates.estimate_number"),
                            error: _vm.estimateNumError,
                            required: "",
                          },
                        },
                        [
                          _c(
                            "sw-input",
                            {
                              staticClass: "mt-2",
                              attrs: {
                                prefix: _vm.estimatePrefix + " - ",
                                invalid: _vm.$v.estimateNumAttribute.$error,
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.$v.estimateNumAttribute.$touch()
                                },
                              },
                              model: {
                                value: _vm.estimateNumAttribute,
                                callback: function ($$v) {
                                  _vm.estimateNumAttribute = $$v
                                },
                                expression: "estimateNumAttribute",
                              },
                            },
                            [
                              _c("hashtag-icon", {
                                staticClass: "h-4 ml-1 text-gray-500",
                                attrs: { slot: "leftIcon" },
                                slot: "leftIcon",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "lg:mt-0",
                          attrs: {
                            label: _vm.$t("estimates.ref_number"),
                            error: _vm.referenceNumError,
                          },
                        },
                        [
                          _c(
                            "sw-input",
                            {
                              staticClass: "mt-2",
                              attrs: {
                                invalid:
                                  _vm.$v.newEstimate.reference_number.$error,
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.$v.newEstimate.reference_number.$touch()
                                },
                              },
                              model: {
                                value: _vm.newEstimate.reference_number,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.newEstimate,
                                    "reference_number",
                                    $$v
                                  )
                                },
                                expression: "newEstimate.reference_number",
                              },
                            },
                            [
                              _c("hashtag-icon", {
                                staticClass: "h-4 ml-1 text-gray-500",
                                attrs: { slot: "leftIcon" },
                                slot: "leftIcon",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "table",
                { staticClass: "w-full text-center item-table" },
                [
                  _c("colgroup", [
                    _c("col", { staticStyle: { width: "40%" } }),
                    _vm._v(" "),
                    _c("col", { staticStyle: { width: "10%" } }),
                    _vm._v(" "),
                    _c("col", { staticStyle: { width: "15%" } }),
                    _vm._v(" "),
                    _vm.discountPerItem === "YES"
                      ? _c("col", { staticStyle: { width: "15%" } })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("col", { staticStyle: { width: "15%" } }),
                  ]),
                  _vm._v(" "),
                  _c(
                    "thead",
                    {
                      staticClass:
                        "bg-white border border-gray-200 border-solid",
                    },
                    [
                      _c("tr", [
                        _c(
                          "th",
                          {
                            staticClass:
                              "px-5 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid",
                          },
                          [
                            _c("span", { staticClass: "pl-12" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$tc("items.item", 2)) +
                                  "\n            "
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            staticClass:
                              "px-5 py-3 text-sm not-italic font-medium leading-5 text-right text-gray-700 border-t border-b border-gray-200 border-solid",
                          },
                          [
                            _c("span", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("estimates.item.quantity")) +
                                  "\n            "
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            staticClass:
                              "px-5 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid",
                          },
                          [
                            _c("span", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("estimates.item.price")) +
                                  "\n            "
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.discountPerItem === "YES"
                          ? _c(
                              "th",
                              {
                                staticClass:
                                  "px-5 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid",
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t("estimates.item.discount")
                                      ) +
                                      "\n            "
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            staticClass:
                              "px-5 py-3 text-sm not-italic font-medium leading-5 text-right text-gray-700 border-t border-b border-gray-200 border-solid",
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "pr-10 column-heading" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("estimates.item.amount")) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "draggable",
                    {
                      staticClass: "item-body",
                      attrs: { tag: "tbody", handle: ".handle" },
                      model: {
                        value: _vm.newEstimate.items,
                        callback: function ($$v) {
                          _vm.$set(_vm.newEstimate, "items", $$v)
                        },
                        expression: "newEstimate.items",
                      },
                    },
                    _vm._l(_vm.newEstimate.items, function (item, index) {
                      return _c("estimate-item", {
                        key: item.id,
                        attrs: {
                          index: index,
                          "item-data": item,
                          currency: _vm.currency,
                          "estimate-items": _vm.newEstimate.items,
                          "tax-per-item": _vm.taxPerItem,
                          "discount-per-item": _vm.discountPerItem,
                        },
                        on: {
                          remove: _vm.removeItem,
                          update: _vm.updateItem,
                          itemValidate: _vm.checkItemsData,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "flex items-center justify-center w-full px-6 py-3 text-base border-b border-gray-200 border-solid cursor-pointer text-primary-400 hover:bg-gray-200",
                  on: { click: _vm.addItem },
                },
                [
                  _c("shopping-cart-icon", { staticClass: "h-5 mr-2" }),
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t("estimates.add_item")) + "\n    "
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "block my-10 estimate-foot lg:flex lg:justify-between lg:items-start",
                },
                [
                  _c(
                    "div",
                    { staticClass: "w-full lg:w-1/2" },
                    [
                      _c(
                        "div",
                        { staticClass: "mb-6" },
                        [
                          _c(
                            "sw-popup",
                            {
                              ref: "notePopup",
                              staticClass:
                                "z-10 text-sm font-semibold leading-5 text-primary-400",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "float-right mt-1",
                                  attrs: { slot: "activator" },
                                  slot: "activator",
                                },
                                [
                                  _vm._v(
                                    "\n              + " +
                                      _vm._s(_vm.$t("general.insert_note")) +
                                      "\n            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("note-select-popup", {
                                attrs: { type: "Estimate" },
                                on: { select: _vm.onSelectNote },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "sw-input-group",
                            { attrs: { label: _vm.$t("estimates.notes") } },
                            [
                              _c("base-custom-input", {
                                attrs: { fields: _vm.EstimateFields },
                                model: {
                                  value: _vm.newEstimate.notes,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.newEstimate, "notes", $$v)
                                  },
                                  expression: "newEstimate.notes",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.customFields.length > 0
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "grid gap-x-4 gap-y-2 md:gap-x-8 md:gap-y-4 grid-col-1 md:grid-cols-2",
                            },
                            _vm._l(_vm.customFields, function (field, index) {
                              return _c(
                                "sw-input-group",
                                {
                                  key: index,
                                  attrs: {
                                    label: field.label,
                                    required: field.is_required ? true : false,
                                  },
                                },
                                [
                                  _c(field.type + "Field", {
                                    tag: "component",
                                    attrs: {
                                      type: field.type.label,
                                      field: field,
                                      "is-edit": _vm.isEdit,
                                      "invalid-fields": _vm.invalidFields,
                                    },
                                    on: { update: _vm.setCustomFieldValue },
                                  }),
                                ],
                                1
                              )
                            }),
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "mt-6 mb-1",
                          attrs: {
                            label: _vm.$t("estimates.estimate_template"),
                            required: "",
                          },
                        },
                        [
                          _c(
                            "sw-button",
                            {
                              staticClass:
                                "flex justify-center w-full text-sm lg:w-auto hover:bg-gray-400",
                              attrs: { type: "button", variant: "gray" },
                              on: { click: _vm.openTemplateModal },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "flex text-black capitalize" },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.getTemplateName) +
                                      "\n              "
                                  ),
                                  _c("pencil-icon", {
                                    staticClass: "h-5 ml-2 -mr-1",
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "px-5 py-4 mt-6 bg-white border border-gray-200 border-solid rounded estimate-total lg:mt-0",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex items-center justify-between w-full",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass:
                                "text-sm font-semibold leading-5 text-gray-500 uppercase",
                            },
                            [_vm._v(_vm._s(_vm.$t("estimates.sub_total")))]
                          ),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass:
                                "flex items-center justify-center m-0 text-lg text-black uppercase",
                            },
                            [
                              _c("div", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$utils.formatMoney(
                                      _vm.subtotal,
                                      _vm.currency
                                    )
                                  ),
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.allTaxes, function (tax) {
                        return _c(
                          "div",
                          {
                            key: tax.tax_type_id,
                            staticClass:
                              "flex items-center justify-between w-full",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass:
                                  "m-0 text-sm font-semibold leading-5 text-gray-500 uppercase",
                              },
                              [
                                _vm._v(
                                  _vm._s(tax.name) +
                                    " - " +
                                    _vm._s(tax.percent) +
                                    "%\n          "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                staticClass:
                                  "flex items-center justify-center m-0 text-lg text-black uppercase",
                                staticStyle: { "font-size": "18px" },
                              },
                              [
                                _c("div", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$utils.formatMoney(
                                        tax.amount,
                                        _vm.currency
                                      )
                                    ),
                                  },
                                }),
                              ]
                            ),
                          ]
                        )
                      }),
                      _vm._v(" "),
                      _vm.discountPerItem === "NO" ||
                      _vm.discountPerItem === null
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "flex items-center justify-between w-full mt-2",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "text-sm font-semibold leading-5 text-gray-500 uppercase",
                                },
                                [_vm._v(_vm._s(_vm.$t("estimates.discount")))]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "flex",
                                  staticStyle: { width: "105px" },
                                  attrs: { role: "group" },
                                },
                                [
                                  _c("sw-input", {
                                    staticClass:
                                      "border-r-0 rounded-tr-sm rounded-br-sm",
                                    attrs: {
                                      invalid:
                                        _vm.$v.newEstimate.discount_val.$error,
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.$v.newEstimate.discount_val.$touch()
                                      },
                                    },
                                    model: {
                                      value: _vm.discount,
                                      callback: function ($$v) {
                                        _vm.discount = $$v
                                      },
                                      expression: "discount",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "sw-dropdown",
                                    { attrs: { position: "bottom-end" } },
                                    [
                                      _c(
                                        "sw-button",
                                        {
                                          staticStyle: { height: "43px" },
                                          attrs: {
                                            slot: "activator",
                                            type: "button",
                                            "data-toggle": "dropdown",
                                            size: "discount",
                                            "aria-haspopup": "true",
                                            "aria-expanded": "false",
                                            variant: "white",
                                          },
                                          slot: "activator",
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "flex" },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.newEstimate
                                                      .discount_type == "fixed"
                                                      ? _vm.currency.symbol
                                                      : "%"
                                                  ) +
                                                  "\n                  "
                                              ),
                                              _c("chevron-down-icon", {
                                                staticClass: "h-5",
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "sw-dropdown-item",
                                        { on: { click: _vm.selectFixed } },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(_vm.$t("general.fixed")) +
                                              "\n              "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "sw-dropdown-item",
                                        { on: { click: _vm.selectPercentage } },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.$t("general.percentage")
                                              ) +
                                              "\n              "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.taxPerItem === "NO" || _vm.taxPerItem === null
                        ? _c(
                            "div",
                            _vm._l(
                              _vm.newEstimate.taxes,
                              function (tax, index) {
                                return _c("tax", {
                                  key: tax.id,
                                  attrs: {
                                    index: index,
                                    total: _vm.subtotalWithDiscount,
                                    tax: tax,
                                    taxes: _vm.newEstimate.taxes,
                                    currency: _vm.currency,
                                    "total-tax": _vm.totalSimpleTax,
                                  },
                                  on: {
                                    remove: _vm.removeEstimateTax,
                                    update: _vm.updateTax,
                                  },
                                })
                              }
                            ),
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.taxPerItem === "NO" || _vm.taxPerItem === null
                        ? _c(
                            "sw-popup",
                            {
                              ref: "taxModal",
                              staticClass:
                                "my-3 text-sm font-semibold leading-5 text-primary-400",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "float-right pt-2 pb-4",
                                  attrs: { slot: "activator" },
                                  slot: "activator",
                                },
                                [
                                  _vm._v(
                                    "\n            + " +
                                      _vm._s(_vm.$t("estimates.add_tax")) +
                                      "\n          "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("tax-select-popup", {
                                attrs: { taxes: _vm.newEstimate.taxes },
                                on: { select: _vm.onSelectTax },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex items-center justify-between w-full pt-2 mt-5 border-t border-gray-200 border-solid",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass:
                                "m-0 text-sm font-semibold leading-5 text-gray-500 uppercase",
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("estimates.total")) +
                                  " " +
                                  _vm._s(_vm.$t("estimates.amount")) +
                                  ":"
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass:
                                "flex items-center justify-center text-lg uppercase text-primary-400",
                            },
                            [
                              _c("div", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$utils.formatMoney(
                                      _vm.total,
                                      _vm.currency
                                    )
                                  ),
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ],
                    2
                  ),
                ]
              ),
            ],
            1
          )
        : _c("base-loader"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }