var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tax-select" }, [
    _c("div", { staticClass: "flex flex-col w-full p-4" }, [
      _c(
        "div",
        { staticClass: "relative flex w-full mb-2" },
        [
          _c("sw-input", {
            staticClass: "text-black",
            attrs: {
              placeholder: _vm.$t("general.search"),
              focus: "",
              icon: "search",
              type: "text",
            },
            model: {
              value: _vm.textSearch,
              callback: function ($$v) {
                _vm.textSearch = $$v
              },
              expression: "textSearch",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.filteredTaxType.length > 0
        ? _c(
            "div",
            {
              staticClass:
                "relative flex flex-col overflow-auto sw-scroll list",
              staticStyle: { "max-height": "112px" },
            },
            _vm._l(_vm.filteredTaxType, function (taxType, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass:
                    "flex justify-between p-4 border-b border-gray-200 border-solid cursor-pointer list-item last:border-b-0 hover:bg-gray-100",
                  class: {
                    "bg-gray-100 cursor-not-allowed opacity-50 pointer-events-none":
                      _vm.taxes.find(function (val) {
                        return val.tax_type_id === taxType.id
                      }),
                  },
                  on: {
                    click: function ($event) {
                      return _vm.selectTaxType(index)
                    },
                  },
                },
                [
                  _c(
                    "label",
                    {
                      staticClass:
                        "inline-block m-0 text-base font-normal leading-tight text-black font-base",
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(taxType.name) + "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass:
                        "inline-block m-0 text-base font-normal leading-tight text-black font-base",
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(taxType.percent) +
                          " %\n        "
                      ),
                    ]
                  ),
                ]
              )
            }),
            0
          )
        : _c("div", { staticClass: "flex justify-center p-5 text-gray-400" }, [
            _c("label", { staticClass: "m-0" }, [
              _vm._v(_vm._s(_vm.$t("general.no_tax_found"))),
            ]),
          ]),
    ]),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass:
          "flex items-center justify-center w-full px-2 py-3 bg-gray-200 border-none outline-none",
        attrs: { type: "button" },
        on: { click: _vm.openTaxModal },
      },
      [
        _c("check-circle-icon", { staticClass: "h-5" }),
        _vm._v(" "),
        _c(
          "label",
          {
            staticClass:
              "m-0 ml-3 text-sm leading-none cursor-pointer font-base text-primary-400",
          },
          [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("invoices.add_new_tax")) + "\n    "
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }