var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex items-center", staticStyle: { "margin-top": "5px" } },
    [
      _c("sw-input", {
        staticStyle: { width: "90%" },
        attrs: { type: "text" },
        on: {
          handleEnter: function ($event) {
            $event.stopPropagation()
            return _vm.onAddOption.apply(null, arguments)
          },
        },
        model: {
          value: _vm.option,
          callback: function ($$v) {
            _vm.option = $$v
          },
          expression: "option",
        },
      }),
      _vm._v(" "),
      _c("plus-circle-icon", {
        staticClass: "ml-1 cursor-pointer text-danger",
        on: { click: _vm.onAddOption },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }