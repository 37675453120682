var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "item-modal" }, [
    _c(
      "form",
      {
        attrs: { action: "" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submitItemData.apply(null, arguments)
          },
        },
      },
      [
        _c(
          "div",
          { staticClass: "px-8 py-8 sm:p-6" },
          [
            _c(
              "sw-input-group",
              {
                staticClass: "mb-4",
                attrs: {
                  label: _vm.$t("items.name"),
                  error: _vm.nameError,
                  variant: "horizontal",
                  required: "",
                },
              },
              [
                _c("sw-input", {
                  ref: "name",
                  attrs: { invalid: _vm.$v.formData.name.$error, type: "text" },
                  on: {
                    input: function ($event) {
                      return _vm.$v.formData.name.$touch()
                    },
                  },
                  model: {
                    value: _vm.formData.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "name", $$v)
                    },
                    expression: "formData.name",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "sw-input-group",
              {
                staticClass: "mb-4",
                attrs: {
                  label: _vm.$t("items.price"),
                  error: _vm.priceError,
                  variant: "horizontal",
                  required: "",
                },
              },
              [
                _c("sw-money", {
                  staticClass:
                    "relative w-full focus:border focus:border-solid focus:border-primary",
                  attrs: {
                    currency: _vm.defaultCurrencyForInput,
                    invalid: _vm.$v.formData.price.$error,
                  },
                  on: {
                    input: function ($event) {
                      return _vm.$v.formData.price.$touch()
                    },
                  },
                  model: {
                    value: _vm.price,
                    callback: function ($$v) {
                      _vm.price = $$v
                    },
                    expression: "price",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "sw-input-group",
              {
                staticClass: "mb-4",
                attrs: { label: _vm.$t("items.unit"), variant: "horizontal" },
              },
              [
                _c("sw-select", {
                  attrs: {
                    options: _vm.itemUnits,
                    searchable: true,
                    "show-labels": false,
                    "max-height": 200,
                    label: "name",
                  },
                  model: {
                    value: _vm.formData.unit,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "unit", $$v)
                    },
                    expression: "formData.unit",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.isTexPerItem
              ? _c(
                  "sw-input-group",
                  {
                    staticClass: "mb-4",
                    attrs: {
                      label: _vm.$t("items.taxes"),
                      variant: "horizontal",
                    },
                  },
                  [
                    _c("sw-select", {
                      attrs: {
                        options: _vm.getTaxTypes,
                        searchable: true,
                        "show-labels": false,
                        "allow-empty": true,
                        multiple: true,
                        label: "tax_name",
                      },
                      model: {
                        value: _vm.formData.taxes,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "taxes", $$v)
                        },
                        expression: "formData.taxes",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "sw-input-group",
              {
                attrs: {
                  label: _vm.$t("items.description"),
                  error: _vm.descriptionError,
                  variant: "horizontal",
                },
              },
              [
                _c("sw-textarea", {
                  attrs: { rows: "4", cols: "50" },
                  on: {
                    input: function ($event) {
                      return _vm.$v.formData.description.$touch()
                    },
                  },
                  model: {
                    value: _vm.formData.description,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "description", $$v)
                    },
                    expression: "formData.description",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "z-0 flex justify-end p-4 border-t border-gray-200 border-solid",
          },
          [
            _c(
              "sw-button",
              {
                staticClass: "mr-3",
                attrs: { variant: "primary-outline", type: "button" },
                on: { click: _vm.closeItemModal },
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("general.cancel")) + "\n      "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "sw-button",
              {
                attrs: {
                  loading: _vm.isLoading,
                  disabled: _vm.isLoading,
                  variant: "primary",
                  type: "submit",
                },
              },
              [
                !_vm.isLoading
                  ? _c("save-icon", { staticClass: "mr-2" })
                  : _vm._e(),
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.isEdit
                        ? _vm.$t("general.update")
                        : _vm.$t("general.save")
                    ) +
                    "\n      "
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }