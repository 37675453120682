var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("sw-input", {
        attrs: {
          type: _vm.type,
          invalid: _vm.isInvalid,
          placeholder: _vm.placeholder,
          tabindex: _vm.tabindex,
        },
        on: { input: _vm.handleInput, change: _vm.handleChange },
        model: {
          value: _vm.inputValue,
          callback: function ($$v) {
            _vm.inputValue = $$v
          },
          expression: "inputValue",
        },
      }),
      _vm._v(" "),
      _vm.isInvalid
        ? _c("span", { staticClass: "text-sm text-danger" }, [
            _vm._v("\n    " + _vm._s(_vm.$t("validation.required")) + "\n  "),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }