var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "relative" },
    [
      _vm.isRequestOnGoing
        ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "sw-card",
        [
          _c(
            "sw-tabs",
            { staticClass: "p-2" },
            [
              _c(
                "sw-tab-item",
                {
                  attrs: {
                    title: _vm.$t("settings.customization.invoices.title"),
                  },
                },
                [_c("invoices-tab", { attrs: { settings: _vm.settings } })],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-tab-item",
                {
                  attrs: {
                    title: _vm.$t("settings.customization.estimates.title"),
                  },
                },
                [_c("estimates-tab", { attrs: { settings: _vm.settings } })],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-tab-item",
                {
                  attrs: {
                    title: _vm.$t("settings.customization.payments.title"),
                  },
                },
                [_c("payments-tab", { attrs: { settings: _vm.settings } })],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-tab-item",
                {
                  attrs: {
                    title: _vm.$t("settings.customization.items.title"),
                  },
                },
                [_c("items-tab")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }