var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-page",
    [
      _c("dashboard-stats"),
      _vm._v(" "),
      _c("dashboard-chart"),
      _vm._v(" "),
      _c("dashboard-table"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }