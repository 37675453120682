var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "flex flex-wrap justify-end mt-8 lg:flex-nowrap" },
        [
          _c(
            "sw-button",
            {
              attrs: { size: "lg", variant: "primary-outline" },
              on: { click: _vm.addItemUnit },
            },
            [
              _c("plus-icon", { staticClass: "w-6 h-6 mr-1 -ml-2" }),
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("settings.customization.items.add_item_unit")) +
                  "\n    "
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "sw-table-component",
        {
          ref: "table",
          attrs: { data: _vm.fetchData, "show-filter": false, variant: "gray" },
        },
        [
          _c("sw-table-column", {
            attrs: {
              sortable: true,
              label: _vm.$t("settings.customization.items.unit_name"),
              show: "name",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("settings.customization.items.unit_name"))
                      ),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "mt-6" }, [
                      _vm._v(_vm._s(row.name)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("sw-table-column", {
            attrs: {
              sortable: false,
              filterable: false,
              "cell-class": "action-dropdown",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("settings.tax_types.action"))),
                    ]),
                    _vm._v(" "),
                    _c(
                      "sw-dropdown",
                      [
                        _c("dot-icon", {
                          staticClass: "h-5 mr-3 text-primary-800",
                          attrs: { slot: "activator" },
                          slot: "activator",
                        }),
                        _vm._v(" "),
                        _c(
                          "sw-dropdown-item",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.editItemUnit(row)
                              },
                            },
                          },
                          [
                            _c("pencil-icon", {
                              staticClass: "h-5 mr-3 text-gray-600",
                            }),
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("general.edit")) +
                                "\n          "
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "sw-dropdown-item",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.removeItemUnit(row.id)
                              },
                            },
                          },
                          [
                            _c("trash-icon", {
                              staticClass: "h-5 mr-3 text-gray-600",
                            }),
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("general.delete")) +
                                "\n          "
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }