var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("sw-select", {
        attrs: {
          options: _vm.options,
          searchable: true,
          "show-labels": false,
          "allow-empty": true,
          invalid: _vm.isInvalid,
          placeholder: _vm.placeholder,
          tabindex: _vm.tabindex,
        },
        on: { select: _vm.onSelectedValueChanged },
        model: {
          value: _vm.selectedValue,
          callback: function ($$v) {
            _vm.selectedValue = $$v
          },
          expression: "selectedValue",
        },
      }),
      _vm._v(" "),
      _vm.isInvalid
        ? _c("span", { staticClass: "text-sm text-danger" }, [
            _vm._v("\n    " + _vm._s(_vm.$t("validation.required")) + "\n  "),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }